import { FileType } from '@ui/common/constants/enums';
import { injectionKeys } from '@ui/injection-keys';
import { FileConfigurationDto } from '@api/models/query';
import { ComputedRef, inject, computed, provide, readonly, Ref, ref } from 'vue';

export interface FileConfiguration {
  acceptedPhotoExtensions: string;
  acceptedVideoExtensions: string;
  acceptedAttachmentExtensions: string;
  acceptedPhotoMimeTypes: string;
  acceptedVideoMimeTypes: string;
  acceptedAttachmentMimeTypes: string;
  maximumPhotoFileSize: number;
  maximumVideoFileSize: number;
  maximumAttachmentFileSize: number;
}

const megaByte = 1024 * 1024;
export const defaultConfiguration: Readonly<FileConfiguration> = readonly({
  maximumPhotoFileSize: 50 * megaByte,
  maximumVideoFileSize: 1024 * megaByte,
  maximumAttachmentFileSize: 100 * megaByte,

  acceptedPhotoExtensions: '.png,.jpeg,.jpg,.gif',
  acceptedVideoExtensions: '.wmv,.mp4,.avi,.3gp,.mpeg,.mpg,.webm,.m4v,.mkv,.3g2,.ogv',
  acceptedAttachmentExtensions: '.xls,.xlsx,.doc,.docx,.ppt,.pptx,.ppsx,.sldx,.pdf,.m4a,.mp3,.wav,.wma,.aac',

  acceptedPhotoMimeTypes: 'image/png,image/jpeg,image/jpeg,image/pjpeg,image/gif',
  acceptedVideoMimeTypes: `
video/x-ms-wmv,video/mp4,video/x-msvideo,video/3gpp,video/mpeg,video/mpeg,video/webm,
video/x-m4v,application/x-troff-msvideo,video/avi,video/msvideo,video/x-matroska,video/3gpp2,video/ogg`,
  acceptedAttachmentMimeTypes: `application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,
application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.slideshow,
application/vnd.openxmlformats-officedocument.presentationml.slide,application/pdf,application/vnd.ms-powerpoint,application/mspowerpoint,
application/mspowerpoint,application/powerpoint,application/x-mspowerpoint,application/excel,application/x-excel,application/x-msexcel,
audio/m4a,audio/mp4,audio/mp4,audio/mpeg,audio/mpg,audio/mp3,audio/wav,audio/wave,audio/x-wav,audio/x-ms-wma,audio/aac`,
});

// Make use of function overloading to have better types
export function useFileConfiguration(): ComputedRef<Readonly<FileConfiguration>>;
export function useFileConfiguration(configuration: Ref<FileConfigurationDto[]>): void;
export function useFileConfiguration(configuration?: Ref<FileConfigurationDto[]>) {
  const key = injectionKeys.configuration.fileConfiguration;

  if (configuration) {
    return provide(key, configuration);
  }

  const injectedConfig = inject(key, ref(undefined));
  return computed(() => {
    if (!injectedConfig.value) {
      return defaultConfiguration;
    }

    const attachmentConfig = injectedConfig.value?.find((config) => config.fileType === FileType.Attachment);
    const photoConfig = injectedConfig.value?.find((config) => config.fileType === FileType.Image);
    const videoConfig = injectedConfig.value?.find((config) => config.fileType === FileType.Video);

    const attachmentExtensions = attachmentConfig?.extensions?.map((extension) => `.${extension.extension}`).join();
    const photoExtensions = photoConfig?.extensions?.map((extension) => `.${extension.extension}`).join();
    const videoExtensions = videoConfig?.extensions?.map((extension) => `.${extension.extension}`).join();

    const attachmentMimeTypes = attachmentConfig?.extensions?.map((extension) => `${extension.mimeType}`).join();
    const photoMimeTypes = photoConfig?.extensions?.map((extension) => `${extension.mimeType}`).join();
    const videoMimeTypes = videoConfig?.extensions?.map((extension) => `${extension.mimeType}`).join();

    return {
      acceptedAttachmentExtensions: attachmentExtensions ?? '',
      acceptedPhotoExtensions: photoExtensions ?? '',
      acceptedVideoExtensions: videoExtensions ?? '',

      acceptedAttachmentMimeTypes: attachmentMimeTypes ?? '',
      acceptedPhotoMimeTypes: photoMimeTypes ?? '',
      acceptedVideoMimeTypes: videoMimeTypes ?? '',

      maximumAttachmentFileSize: (attachmentConfig?.maxFileSizeMb ?? 1024) * megaByte,
      maximumPhotoFileSize: (photoConfig?.maxFileSizeMb ?? 50) * megaByte,
      maximumVideoFileSize: (videoConfig?.maxFileSizeMb ?? 100) * megaByte,
    };
  });

}
